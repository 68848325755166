<template>
  <v-form v-model="form" ref="form" autocomplete="off">
    <v-row no-gutters>
      <v-col xs="12" cols="12" class="form-row">
        <v-text-field
          v-model="user.username"
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          :rules="[
            (v) => !!v || $t('UsernameRequired'),
            (v) => (v && v.length <= 45) || $t('UsernameMaxLength'),
            (v) => (v && v.length >= 3) || $t('UsernameToShort'),
            (v) => /^[a-zA-Z0-9_.]+$/.test(v) || $t('UsernameNotValid'),
          ]"
          :disabled="is_authorizing"
          :label="$t('Username')"
          required
        ></v-text-field>
      </v-col>
      <v-col xs="12" cols="12" class="form-row">
        <v-text-field
          v-model="user.email"
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          :rules="[
            (v) => !!v || $t('Validation.EmailRequired'),
            (v) => /.+@.+\..+/.test(v) || $t('Validation.EmailNotValid'),
          ]"
          :disabled="is_authorizing"
          :label="$t('Email')"
          required
        ></v-text-field>
      </v-col>
      <v-col xs="12" cols="12" class="form-row">
        <v-text-field
          v-model="user.password"
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          :rules="[
            (v) => !!v || $t('PasswordRequired'),
            (v) => (v && v.length > 8) || $t('PasswordToShort'),
          ]"
          :disabled="is_authorizing"
          :label="$t('Password')"
          :append-icon="show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
          :type="show1 ? 'text' : 'password'"
          :hint="$t('MinPasswordLength')"
          counter
          @click:append="show1 = !show1"
          required
        ></v-text-field>
      </v-col>

      <v-col xs="12" cols="12">
        <v-btn
          block
          :loading="is_authorizing && !fb_authorizing"
          :disabled="fb_authorizing"
          color="primary"
          @click="sendForm"
          class="text-none mt-5"
          >{{ $t("Button.Send") }}</v-btn
        >
      </v-col>

      <v-col xs="12" cols="12" v-if="error && !is_authorizing">
        <v-alert
          text
          prominent
          type="error"
          icon="$vuetify.icons.cloudAlert"
          class="mt-5"
        >
          <p>{{ error }}</p>
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<i18n>
    {
    "en": {
    "MinPasswordLength": "At least 8 characters",
    "Username": "Username",
    "Email": "Email",
    "Password": "Password",
    "PasswordRequired": "Password is missing!",
    "UsernameRequired": "Username is required!",
    "PasswordToShort": "Password to short",
    "UsernameMaxLength": "Username must be less than 45 characters",
    "UsernameNotValid": "Username not valid. Use a-zA-Z0-9_.",
    "UsernameToShort": "Username to short. At least 3 characters"
    },
    "sv": {
    "MinPasswordLength": "Minst 8 tecken",
    "Username": "Användarnamn",
    "Email": "Email",
    "Password": "Lösenord",
    "PasswordRequired": "Lösenord saknas!",
    "UsernameRequired": "Användarnamn saknas!",
    "PasswordToShort": "Lösenordet är för kort",
    "UsernameMaxLength": "Användarnamnet får inte vara längre än 45 tecken",
    "UsernameNotValid": "Användarnamnet är inte korrekt. Använd a-zA-Z0-9_.",
    "UsernameToShort": "Användarnamnet för kort. Minst 3 tecken"
    }
    }
</i18n>


<script>
import { responseHelpers } from "@/_helpers";
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      error: null,
      form: false,
      show1: false,

      user: {
        email: "",
        password: "",
        username: "",
      },
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
      },
    };
  },
  computed: {
    ...mapState({
      is_authorizing: (state) => state.account.status.is_authorizing,
      fb_authorizing: (state) => state.account.status.fb_authorizing,
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      create: "account/createSimple",
    }),
    sendForm() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self
          .create(self.user)
          .then(function (response) {
            self.$emit("done", response);
          })
          .catch(function (error) {
            self.error = responseHelpers.errorTextFromResponse(error);
          });
      }
    },
  },
};
</script>