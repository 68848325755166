<template>
  <v-form v-model="form" ref="form">
    <v-row  no-gutters>
      <v-col xs="12" cols="12" class="form-row">
        <v-text-field
          v-model="user.username"
          :rules="[(v) => !!v || $t('UsernameRequired')]"
          :disabled="is_authorizing"
          :label="$t('Username')"
          required
        ></v-text-field>
      </v-col>
      <v-col xs="12" cols="12" class="form-row">
        <v-text-field
          v-model="user.password"
          :rules="[(v) => !!v || $t('PasswordRequired')]"
          :disabled="is_authorizing"
          :label="$t('Password')"
          :append-icon="show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff'"
          :type="show1 ? 'text' : 'password'"
          @click:append="show1 = !show1"
          required
        ></v-text-field>
      </v-col>
      <v-col xs="12" cols="12">
        <v-btn
          block
          :loading="is_authorizing"
          color="primary"
          @click="sendForm"
          class="text-none mt-5"
          >{{ $t("Button.Send") }}</v-btn
        >
      </v-col>
      <v-col xs="12" cols="12" v-if="error && !is_authorizing">
        <v-alert text prominent type="error" icon="$vuetify.icons.cloudAlert" class="mt-5">
          <p class="caption">{{ error }}</p>
        </v-alert>
      </v-col>
    </v-row>
  </v-form>
</template>

<i18n>
    {
    "en": {
    "Username": "Username",
    "Password": "Password",
    "UsernameRequired": "Username is missing!",
    "PasswordRequired": "Password is missing!"
    },
    "sv": {
    "Username": "Användarnamn",
    "Password": "Lösenord",
    "UsernameRequired": "Användarnamn saknas!",
    "PasswordRequired": "Lösenord saknas!"
    }
    }
</i18n>


<script>
import { mapState, mapActions } from "vuex";
import { responseHelpers } from "@/_helpers";

export default {
  data() {
    return {
      form: false,
      show1: false,
      error: null,
      
      user: {
        password: "",
        username: "",
      },
    };
  },
  computed: {
    ...mapState({
      is_authorizing: (state) => state.account.status.is_authorizing,
      fb_authorizing: (state) => state.account.status.fb_authorizing,
    }),
  },
  created() {},
  methods: {
    ...mapActions({
      login: "account/login",
    }),
    sendForm() {
      var self = this;
      self.error = null;

      if (self.$refs.form.validate()) {
        self
          .login(self.user)
          .then(function (response) {
            self.$emit("done", response);
          })
          .catch(function (error) {
            self.error = responseHelpers.errorTextFromResponse(error);
          });
      }
    },
  },
};
</script>