<template>
  <div class="fill-height">
    <v-container class="pb-15 mb-15">
      <v-skeleton-loader :loading="invite.is_loading" type="article">
        <error-page :error="invite.error"></error-page>

        <div v-if="!invite.error && invite.data.blog" min-height="400">
          <v-card-text class="text-center pt-12">
            <h2 class="mb-8">{{ $t("YouAreInvitedTo") }}</h2>

            <avatar :diary="invite.data.blog" :size="150"></avatar>

            <h1 class="mb-5 mt-5">{{ invite.data.blog.title | capitalize }}</h1>
            <p v-if="invite.data.permission == $enums.DIARY_ROLE.Reader">
              {{
                $t("RightsToRead", {
                  username: invite.data.invited_by.username,
                })
              }}
            </p>
            <p v-if="invite.data.permission == $enums.DIARY_ROLE.Admin">
              {{
                $t("AdminRights", {
                  username: invite.data.invited_by.username,
                })
              }}
            </p>
          </v-card-text>

          <!--- Show, else event not trigged -->
          <div v-show="!user">
            <p
              class="text-center caption"
              v-html="$t('AccountRequiredInfo')"
            ></p>

            <v-card class="mx-auto mt-7 mb-10" max-width="400" outlined>
              <v-tabs grow v-model="tab">
                <v-tab key="login">{{ $t("Login") }}</v-tab>
                <v-tab key="signup">{{ $t("Signin") }}</v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item key="login">
                  <v-card flat>
                    <v-card-text>
                      <login-form v-on:done="onAuthorized"></login-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item key="signup">
                  <v-card flat>
                    <v-card-text>
                      <create-form v-on:done="onAuthorized"></create-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </div>

          <div v-if="user" class="text-center">
            <v-btn
              @click="acceptInvite"
              large
              color="primary"
              :loading="accept_invite.is_saving"
              >{{ $t("AcceptInvite") }}</v-btn
            >

            <error-inline :error="accept_invite.error"></error-inline>
          </div>
        </div>
      </v-skeleton-loader>
    </v-container>
  </div>
</template>

<i18n>
    {
    "en": {
    "Invite": "Invite",
    "YouAreInvitedTo": "You are invited to diary",
    "AcceptInvite": "Accept invite",
    "RightsToRead": "{username} has give you access to read the diary.",
    "AdminRights": "{username} has give you access to read and administrate the diary.",
    "Login": "Login",
    "Signin": "Sign in",
    "AccountRequiredInfo": "You must be logged in to accept the invitation. <br /> If you do not have an account, you can create one for free below."
    },
    "sv": {
    "Invite": "Inbjudan",
    "YouAreInvitedTo": "Du är inbjuden till dagboken",
    "AcceptInvite": "Aceptera inbjudan",
    "RightsToRead": "{username} har givit dig rättighet att läsa dagboken.",
    "AdminRights": "{username} har givit dig rättighet att läsa och administrera dagboken.",
    "Login": "Logga in",
    "Signin": "Bli medlem",
    "AccountRequiredInfo": "Du måste vara inloggad för att acceptera inbjudan.<br />Har du inget konto kan du skapa ett kostnadsfritt nedan."
    }
    }
</i18n>

<script>
import { globalService } from "../../_services";
import { mapState, mapActions } from "vuex";
import Avatar from "../../components/avatars/BlogAvatar.vue";
import LoginForm from "../../components/global/account/Login.vue";
import CreateUserForm from "../../components/global/account/CreateSimpleAccount.vue";

export default {
  name: "invite",
  components: {
    avatar: Avatar,
    "login-form": LoginForm,
    "create-form": CreateUserForm,
  },
  metaInfo() {
    return {
      title: this.$t("Invite"),
      meta: [
        {
          property: "og:title",
          content: this.$t("Invite") + " | " + process.env.VUE_APP_NAME,
          vmid: "og:title",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),
  },
  data: () => ({
    invite: {
      error: null,
      data: {},
      is_loading: false,
    },
    accept_invite: {
      is_saving: false,
      error: null,
    },
    tab: null,
    key: "",
  }),
  created: function () {
    var self = this;

    this.key = this.$route.query.key;
    //var email = this.$route.query.email;

    if (!this.key) {
      this.$router.push("/");
      return;
    }

    self.invite.is_loading = true;

    globalService
      .getInvite(this.key)
      .then(function (response) {
        self.invite.data = response.data;
        self.invite.is_loading = false;
      })
      .catch(function (error) {
        self.invite.error = error;
        self.invite.is_loading = false;
      });
  },
  methods: {
    ...mapActions({
      reload: "account/reloadUser",
    }),
    onAuthorized() {
      this.acceptInvite();
    },
    acceptInvite() {
      var self = this;

      if (!self.key) {
        this.$router.push("/");
        return;
      }

      self.accept_invite.is_saving = true;
      self.accept_invite.error = null;

      globalService
        .acceptInvite(self.key)
        .then(function () {
          self
            .reload()
            .then(function () {
              self.$router.push("/");
            })
            .catch(function () {
              window.location.reload();
            });
        })
        .catch(function (error) {
          self.accept_invite.error = error;
          self.accept_invite.is_saving = false;
        });
    },
  },
};
</script>


<style scoped>
.text-center {
  text-align: center !important;
}
</style>