var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",attrs:{"autocomplete":"off"},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"form-row",attrs:{"xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"autocorrect":"off","spellcheck":"false","autocomplete":"off","rules":[
          function (v) { return !!v || _vm.$t('UsernameRequired'); },
          function (v) { return (v && v.length <= 45) || _vm.$t('UsernameMaxLength'); },
          function (v) { return (v && v.length >= 3) || _vm.$t('UsernameToShort'); },
          function (v) { return /^[a-zA-Z0-9_.]+$/.test(v) || _vm.$t('UsernameNotValid'); } ],"disabled":_vm.is_authorizing,"label":_vm.$t('Username'),"required":""},model:{value:(_vm.user.username),callback:function ($$v) {_vm.$set(_vm.user, "username", $$v)},expression:"user.username"}})],1),_c('v-col',{staticClass:"form-row",attrs:{"xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"autocorrect":"off","spellcheck":"false","autocomplete":"off","rules":[
          function (v) { return !!v || _vm.$t('Validation.EmailRequired'); },
          function (v) { return /.+@.+\..+/.test(v) || _vm.$t('Validation.EmailNotValid'); } ],"disabled":_vm.is_authorizing,"label":_vm.$t('Email'),"required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1),_c('v-col',{staticClass:"form-row",attrs:{"xs":"12","cols":"12"}},[_c('v-text-field',{attrs:{"autocorrect":"off","spellcheck":"false","autocomplete":"off","rules":[
          function (v) { return !!v || _vm.$t('PasswordRequired'); },
          function (v) { return (v && v.length > 8) || _vm.$t('PasswordToShort'); } ],"disabled":_vm.is_authorizing,"label":_vm.$t('Password'),"append-icon":_vm.show1 ? '$vuetify.icons.eye' : '$vuetify.icons.eyeOff',"type":_vm.show1 ? 'text' : 'password',"hint":_vm.$t('MinPasswordLength'),"counter":"","required":""},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})],1),_c('v-col',{attrs:{"xs":"12","cols":"12"}},[_c('v-btn',{staticClass:"text-none mt-5",attrs:{"block":"","loading":_vm.is_authorizing && !_vm.fb_authorizing,"disabled":_vm.fb_authorizing,"color":"primary"},on:{"click":_vm.sendForm}},[_vm._v(_vm._s(_vm.$t("Button.Send")))])],1),(_vm.error && !_vm.is_authorizing)?_c('v-col',{attrs:{"xs":"12","cols":"12"}},[_c('v-alert',{staticClass:"mt-5",attrs:{"text":"","prominent":"","type":"error","icon":"$vuetify.icons.cloudAlert"}},[_c('p',[_vm._v(_vm._s(_vm.error))])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }